



















































































































import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator'
import set from 'lodash/set'

import { SelectItem } from '../../../../dsl/atoms/Select'
import { StructureConfigurable } from '../../../../support/mixins'

import { authSSOCustomerData } from '../../../auth/config/auth'
import { ShipmentRegionsMixin } from '../../../shared/mixins/shipment-regions.mixin'
import { ShipmentRegion } from '../../../shared/services/dictionary'

import { CheckoutPayload } from '../../contracts'

import { GuestDetailsFormFieldset } from './GuestDetailsFormFieldset.vue'
import {
  GuestDetailsFormFieldsetConfig,
  SSOContextMode
} from './GuestDetailsFormFieldset.contracts'
import { FormContextSwitchContracts } from '../FormContextSwitch/FormContextSwitch.contracts'
import {
  GUEST_DETAILS_FORM_FIELDSET_CONFIG_MAP,
  GUEST_DETAILS_FORM_FIELDSET_KEY
} from './GuestDetailsFormFieldset.config'
import RadioSwitch from '../FormContextSwitch/RadioSwitch.vue'
import { AnyObject } from '@movecloser/front-core'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<GuestDetailsFormFieldsetSSO>({
  name: 'GuestDetailsFormFieldsetSSO',
  components: { RadioSwitch },
  created (): void {
    this.config = this.getComponentConfig(GUEST_DETAILS_FORM_FIELDSET_KEY, { ...GUEST_DETAILS_FORM_FIELDSET_CONFIG_MAP })
    this.composeSsoFormContexts()
    this.retrieveShipmentRegions(this.hasRegions)
  },
  mounted (): void {
    // Set company if present.
    if ((this._formData.address.invoice?.company && this._formData.address.invoice?.company.length > 0) &&
      (this._formData.address.invoice?.nip && this._formData.address.invoice?.nip.length > 0)
    ) {
      this.isCompany = true
    }

    if (this.hasShipmentRegions && Object.prototype.hasOwnProperty.call(this._formData.address, 'region')) {
      this.regionCode = (this._formData.address.region as ShipmentRegion).code
    }

    const SSOCustomer = localStorage.getItem(authSSOCustomerData)
    if (SSOCustomer) {
      this._formData = {
        ...this._formData,
        user: {
          ...this._formData.user,
          email: JSON.parse(SSOCustomer).email ?? ''
        }
      }
      this.emailDisabled = true
    }
  }
})
export class GuestDetailsFormFieldsetSSO extends Mixins(GuestDetailsFormFieldset, ShipmentRegionsMixin, StructureConfigurable) {
  /**
   * @override
   */
  @PropSync('formData', { type: Object, required: true })
  protected _formData!: CheckoutPayload

  @PropSync('ssoMode', { type: String, required: false, default: SSOContextMode.Guest })
  protected _ssoMode!: string

  @Prop({ type: Boolean, required: false, default: false })
  public readonly withRegistrationFields!: boolean

  @Prop({ type: Boolean, required: false, default: true })
  public readonly withCompanyCheckboxFields!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly withConsentFields!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly canRegisterAsCompany!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly hasRegions!: boolean

  public isCompany: boolean = false
  public emailDisabled: boolean = false
  public regionCode?: string = ''
  public ssoFormModes = SSOContextMode
  public ssoFormContexts: FormContextSwitchContracts | null = null
  protected config!: GuestDetailsFormFieldsetConfig

  public get displaySwitch (): boolean {
    return this.hasSSOContextSwitcher && !!this.ssoFormContexts?.radio &&
      this.ssoFormContexts.radio.length > 1
  }

  public get hasSSOContextSwitcher (): boolean {
    return this.getConfigProperty('hasSSOContextSwitcher')
  }

  public get company (): string {
    if (!this.isCompany) {
      return this._formData.address.company
    } else {
      return this._formData.address.invoice?.company ?? ''
    }
  }

  public set company (val: string) {
    this.updateModel('address.company', val)

    if (this.isCompany) {
      this.updateModel('address.invoice.company', val)
    }
  }

  public composeSsoFormContexts () {
    let hasGuest = true
    if (this.siteService) {
      const settings = this.siteService.getProperty('settings') as AnyObject | null

      hasGuest = !settings?.onlyCompany

      if (!hasGuest) {
        this._ssoMode = SSOContextMode.Company
      }
    }

    this.ssoFormContexts = {
      radio: [
        ...(hasGuest ? [{
          name: 'ssoCustomerContext',
          label: SSOContextMode.Guest,
          value: SSOContextMode.Guest,
          id: SSOContextMode.Guest
        }] : []),
        {
          name: 'ssoCustomerContext',
          label: SSOContextMode.Company,
          value: SSOContextMode.Company,
          id: SSOContextMode.Company
        }
      ]
    }
  }

  public get hasShipmentRegions (): boolean {
    return Array.isArray(this.shipmentRegions) && this.shipmentRegions.length > 0
  }

  public get regionOptions (): Array<SelectItem> | undefined {
    if (!this.hasShipmentRegions) {
      return
    }

    return this.shipmentRegions!.map((region) => {
      return {
        label: region.name,
        value: region.code
      }
    })
  }

  public onRegionUpdate (value: string): void {
    this.regionCode = value

    if (this.shipmentRegions) {
      this.updateModel('address.region', this.shipmentRegions.find((region) => region.code === this.regionCode))
    }
  }

  /**
   * Updates model (works with nested objects reactivity (reference))
   * @param key - `first.second.third.x`
   * @param value - `value for key`
   */
  public updateModel (key: string, value: unknown): void {
    const newFormData = { ...this._formData }

    set(newFormData, key, value)

    this._formData = newFormData

    this.$emit('update:errors')
  }
}

export default GuestDetailsFormFieldsetSSO
