

















































import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator'
import { Authentication, AuthServiceType } from '@movecloser/front-core'
import { VueConstructor } from 'vue'

import { StructureConfigurable } from '../../../../../support/mixins'
import { Inject } from '../../../../../support/plugins/front-core'
import { logger } from '../../../../../support'

import { AuthControlServiceType, IAuthControl } from '../../../../auth/contracts'
import { authSSOSelectedProvider, AuthSSOSource, authSSOSource } from '../../../../auth/config/auth'
import { SSOAuthMixin, UserModel } from '../../../../auth/shared'

import {
  FormContextSwitchContracts
} from '../../../molecules/FormContextSwitch/FormContextSwitch.contracts'
import RadioSwitch from '../../../molecules/FormContextSwitch/RadioSwitch.vue'

import { BuyerContextSwitch } from '../BuyerContextSwitch.vue'
import {
  BUYER_CONTEXT_SWITCH_COMPONENT_KEY,
  defaultConfig,
  loginRegisterContextComponentsRegistry
} from '../BuyerContextSwitch.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<LoginAndRegister>({
  name: 'LoginAndRegister',
  components: { RadioSwitch },
  created (): void {
    this.config = this.getComponentConfig(BUYER_CONTEXT_SWITCH_COMPONENT_KEY, defaultConfig)
  }
})
export class LoginAndRegister extends Mixins(BuyerContextSwitch, SSOAuthMixin, StructureConfigurable) {
  @Inject(AuthControlServiceType)
  protected readonly authControl!: IAuthControl

  @Inject(AuthServiceType)
  protected readonly authService!: Authentication<UserModel>

  @Prop({ type: Boolean, required: false, default: true })
  public readonly withSocials!: boolean

  @PropSync('mode', { type: String, required: true })
  public readonly _mode!: string

  public socialPending: boolean = false
  protected config!: any

  /**
   * Determines a state when there is no SSO authentication process
   */
  public get noSSOAuthentication (): boolean {
    const ssoAuthenticated = this.$store.getters['checkout/ssoAuthenticated']
    const ssoConsentsRequest = this.$store.getters['checkout/ssoConsentsRequest']
    return !ssoAuthenticated && !ssoConsentsRequest
  }

  public get loginRegisterComponent (): VueConstructor | undefined {
    if (!(this.loginRegisterContext in loginRegisterContextComponentsRegistry)) {
      return
    }
    return loginRegisterContextComponentsRegistry[this.loginRegisterContext]
  }

  public get loginRegisterContext (): string {
    return this.getConfigProperty('loginRegisterContextComponent')
  }

  public get loginRegisterContextProps (): Array<FormContextSwitchContracts> {
    return this.getConfigProperty('loginRegisterContextProps')
  }

  /**
   * Callback to sign with socials.
   */
  public async socialLogin (social: string): Promise<void> {
    try {
      this.socialPending = true

      /** Stores selected social provider */
      localStorage.setItem(authSSOSelectedProvider, social)
      /** Stores selected source of authentication */
      localStorage.setItem(authSSOSource, AuthSSOSource.Cart)

      const redirectUri = await this.authControl.getSocialAuthRedirectUri(social)

      if (redirectUri) {
        window.location.href = redirectUri
      }
    } catch (error) {
      logger(error, 'warn')
    } finally {
      this.socialPending = false
    }
  }
}
export default LoginAndRegister
