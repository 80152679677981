


















































import { Component, Mixins, Prop } from 'vue-property-decorator'

import { AddressData } from '../../../../contexts'

import { UserMixin } from '../../../profile/shared'
import { AuthMixin } from '../../../auth/shared'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<AddressSelector>({
  name: 'AddressSelector',
  mounted (): void {
    if (this.user) {
      if (!this.hasAddress) {
        return
      }

      const newAddressCandidate = this.addresses.filter((address) => {
        return address.defaultBilling
      })[0]

      this.$emit('onMounted', {
        id: newAddressCandidate.id,
        address: newAddressCandidate
      })
    }
  }
})
export class AddressSelector extends Mixins(UserMixin, AuthMixin) {
  @Prop({ type: Boolean, required: false, default: false })
  public readonly hasRegionField!: boolean

  @Prop({ type: Boolean, required: false, default: true })
  public readonly canEdit!: boolean

  /**
   * Determines the selected address.
   */
  public get selected (): number | undefined {
    return this.addresses.filter((address) => {
      return address.defaultBilling
    })[0].id
  }

  public set selected (value: number | undefined) {
    const newAddressCandidate = this.addresses.filter((address) => address.id === value)[0]
    this.$emit('onAddress', { id: newAddressCandidate.id, address: newAddressCandidate })
  }

  /**
   * Determines the list of addresses that user have.
   */
  public get addresses (): AddressData[] {
    if (!this.user) {
      return []
    }

    return this.user.addresses
  }

  /**
   * Determines whether user has an address
   */
  public get hasAddress (): boolean {
    return this.addresses.length > 0
  }

  /**
   * Emits @edit event with selected address.
   */
  public onEdit (id: number): void {
    const newAddressCandidate = this.addresses.filter((address) => address.id === id)[0]
    this.$emit('edit', { id: newAddressCandidate.id, address: newAddressCandidate })
  }

  /**
   * Determines whether given address is for company
   */
  public isCompany (address: AddressData): boolean {
    return !!address.vatId && address.vatId.length > 0
  }
}

export default AddressSelector
