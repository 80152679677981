
import { GuestDetailsFormFieldsetConfig } from './GuestDetailsFormFieldset.contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const GUEST_DETAILS_FORM_FIELDSET_KEY = 'GuestDetailsFormFieldsetComponent'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const GUEST_DETAILS_FORM_FIELDSET_CONFIG_MAP: GuestDetailsFormFieldsetConfig = {
  hasSSOContextSwitcher: false
}
